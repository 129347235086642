<template>
    <div
        class="user-box-navigation"
    >
        <div class="user-box-money">
            {{ balance }} &#8376;
        </div>
        <div class="user-box-logo">
            <img
                src="@/assets/avatar.svg"
                alt="avatar"
            >
        </div>
        <div class="user-box-mail">
            {{ email }}

            <div class="user-box-menu">
                <router-link
                    class="user-box-menu-item"
                    to="/supplier/support"
                >
                    <img
                        src="@/assets/icon_dropdown_help.svg"
                        alt="help"
                    >
                    Помощь
                </router-link>
                <router-link
                    class="user-box-menu-item"
                    :to="`/supplier/auth/recover/${supplierStore.authToken}`"
                >
                    <img
                        src="@/assets/icon_dropdown_edit.svg"
                        alt="edit"
                    >
                    Обновить пароль
                </router-link>
                <div
                    class="user-box-menu-item"
                    @click="logout"
                >
                    <img
                        src="@/assets/icon_dropdown_logout.svg"
                        alt="logout"
                    >
                    Выйти
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useSupplierStore } from '@/stores/supplier';

import { jwtDecode } from 'jwt-decode';

export default defineComponent({
  setup () {
    const supplierStore = useSupplierStore();

    supplierStore.walletBalance();

    return {
      supplierStore
    };
  },
  computed: {
    balance (): number {
      return this.supplierStore.wallet.balance;
    },
    email (): string {
      if(this.supplierStore.authToken) {
        const { email } = jwtDecode(this.supplierStore.authToken) as {email: string};

        return email;
      }
      return '';
    }
  },
  methods: {
    logout() {
      this.supplierStore.logout()
      this.$router.push('/supplier/auth/#form_login')
    }
  }
});
</script>

<style lang="less">

.user-box-navigation {
  padding: 14px 0;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-box-logo {
  margin: 0 40px;

  img {
    display: block;
  }
}

.user-box-money {
  font-size: 20px;
  color: #E88102;
}

.user-box-mail {
  cursor: pointer;
  position: relative;

  &:hover {
    .user-box-menu {
      display: block;
    }
  }
}

.user-box-menu {
  border-radius: 2px;
  display: none;
  width: 100%;
  padding: 4px 0;
  top: 100%;
  left: 0;
  position: absolute;
  background: #38363B;
}

.user-box-menu-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  padding: 6px 12px;
  color: #ffffff;

  img {
    margin-right: 5px
  }

  &:hover {
    background: #242527;
  }
}



</style>
