<template>
  &nbsp;
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  created() {
    if(window.parent) {
      window.parent.postMessage({
        type: 'supplierRequestId',
        id: this.$route.params.id
      });
    }
  }
});
</script>
