import {
    RequestPayoutDestination,
    RequestPayoutDestinationAdd,
    RequestPayoutDestinationRemove,
    RequestPayoutLimits,
    RequestPayoutsCreate,
    RequestRestorePassword,
    ResponsePayoutDestination,
    ResponsePayoutDestinationAdd,
    ResponsePayoutDestinationRemove,
    ResponsePayoutLimits,
    ResponsePayoutsCreate,
    ResponseRestorePassword
} from '@/api/supplier/types';

import {SUPPLIER_URL_API} from '@/api/urls';

export const postDataRestorePassword = async (param: RequestRestorePassword): Promise<ResponseRestorePassword> => {
    const response = await fetch(`${SUPPLIER_URL_API}/auth/recover-password`, {
        body: JSON.stringify(param),
        method: 'POST',
        headers: {'Content-Type': 'application/json'}
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`);
    }
};

export const getDataPayoutDestination = async (param: RequestPayoutDestination): Promise<ResponsePayoutDestination> => {
    const response = await fetch(`${SUPPLIER_URL_API}/wallet/payout-destination/list`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`
        }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`);
    }
};

export const getDataPayoutLimits = async (param: RequestPayoutLimits): Promise<ResponsePayoutLimits> => {
    const response = await fetch(`${SUPPLIER_URL_API}/wallet/payouts/limits`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`
        }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`);
    }
};

export const getDataPayoutsCreate = async (param: RequestPayoutsCreate): Promise<ResponsePayoutsCreate> => {
    const response = await fetch(`${SUPPLIER_URL_API}/wallet/payouts/create`, {
        body: JSON.stringify(param),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`
        }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`);
    }
};

export const postDataPayoutDestinationRemove = async (param: RequestPayoutDestinationRemove): Promise<ResponsePayoutDestinationRemove> => {
    const response = await fetch(`${SUPPLIER_URL_API}/wallet/payout-destination/remove`, {
        body: JSON.stringify(param),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`
        }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`);
    }
};

export const postDataPayoutDestinationAdd = async (param: RequestPayoutDestinationAdd): Promise<ResponsePayoutDestinationAdd> => {
    const response = await fetch(`${SUPPLIER_URL_API}/wallet/payout-destination/add`, {
        body: JSON.stringify(param),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`
        }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`);
    }
};
