<template>
    <div class="home-content">
        <popup-error v-if="globalStore.showPopup.errorRequest" />
        <popup-restore-password-error v-if="globalStore.showPopup.errorRestorePassword" />
        <popup-success-restore-email v-if="globalStore.showPopup.successRestoreEmail" />
        <popup-success-registration v-if="globalStore.showPopup.successRegistration" />
        <form-login v-else-if="globalStore.showPopup.formLogin" />
        <form-registration v-else-if="globalStore.showPopup.formRegistration" />
        <form-restore-email v-else-if="globalStore.showPopup.formRestoreEmail" />
        <popup-verification v-if="globalStore.showPopup.profileVerification" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global';

import PopupError from '@/components/popup/PopupError.vue';
import PopupRestorePasswordError from '@/components/popup/PopupRestorePasswordError.vue';
import PopupSuccessRestoreEmail from '@/components/popup/PopupSuccessRestoreEmail.vue';
import PopupSuccessRegistration from '@/components/popup/PopupSuccessRegistration.vue';
import PopupVerification from '@/components/popup/PopupVerification.vue';
import FormLogin from '@/components/form/FormLogin.vue';
import FormRegistration from '@/components/form/FormRegistration.vue';
import FormRestoreEmail from '@/components/form/FormRestoreEmail.vue';
import { COMPONENT_NAME } from '@/stores/types';


export default defineComponent({
  components: {
    PopupSuccessRestoreEmail,
    PopupSuccessRegistration,
    PopupError,
    PopupRestorePasswordError,
    FormLogin,
    PopupVerification,
    FormRegistration,
    FormRestoreEmail
  },
  setup () {
    const globalStore = useGlobalStore();

    globalStore.setShowPopup(COMPONENT_NAME.FORM_LOGIN)
    return {
      globalStore
    };
  }
});
</script>
