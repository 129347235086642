<template>
    <div
        class="template-navigation"
    >
        <div
            :class="{
                'template-navigation-icon-mobile': true,
                'close' : mobileMenuOpen
            }"
            @click="handleMobileMenuChange"
        />
        <div
            :class="{
                'template-navigation-link': true,
                'template-navigation-link-full' : mobileMenuOpen
            }"
        >
            <router-link
                to="/steam/#form_pay"
                @click="() => {
                    handleRouterChange()
                }"
            >
                Пополнить Steam
            </router-link>
            <router-link
                to="/steam/#faq"
                @click="() => {
                    handleRouterChange()
                }"
            >
                FAQ
            </router-link>
            <router-link
                to="/steam/#form_support"
                @click="() => {
                    handleRouterChange()
                }"
            >
                Задать вопрос
            </router-link>
<!--            <router-link-->
<!--                class="template-navigation-login"-->
<!--                to="/supplier/auth/#form_login"-->
<!--                @click="() => {-->
<!--                    handleRouterChange()-->
<!--                }"-->
<!--            >-->
<!--                Вход-->
<!--            </router-link>-->
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global';
export default defineComponent({
  setup () {
    const globalStore = useGlobalStore();
    return {
      globalStore
    };
  },
  data() {
    return {
      mobileMenuOpen: false
    }
  },
  methods: {
    handleRouterChange() {
      this.mobileMenuOpen = false;
      document.body.style.overflow = 'visible';
    },
    handleMobileMenuChange() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
      document.body.style.overflow = this.mobileMenuOpen ? 'hidden' : 'visible';
    }
  }
});
</script>

<style lang="less">

</style>
