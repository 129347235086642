<template>
    <div
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <div class="form-text-status-title">
                Подтвердите email
            </div>
            <div class="form-text-status-description">
                Для завершения регистрации перейдите по ссылке в письме, отправленном на указанный адрес электронной почты
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useClientStore } from '@/stores/client';
import { useGlobalStore } from '@/stores/global';
import dayjs from 'dayjs';

export default defineComponent({
  setup () {
    const globalStore = useGlobalStore();
    return {
      setShowPopup: globalStore.setShowPopup
    };
  }
});
</script>
