<template>
    <div class="template-bg template-main-bg">
        <div class="template-wrap">
            <div class="template-header-wrap">
                <div class="template-header-logo">
                    <router-link
                        to="/steam/#form_pay"
                    >
                        <img
                            src="@/assets/logo_template.svg"
                            alt="topupme"
                        >
                    </router-link>
                </div>
                <steam-navigation />
            </div>
            <div class="template-content-wrap">
                <slot />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SteamNavigation from '@/components/SteamNavigation.vue';

export default defineComponent({
  components: {
    SteamNavigation
  }
});
</script>
