<template>
    <div
        class="form-wrap"
    >
        <div

            class="form-content"
        >
            <h2>
                Подтвердите аккаунт
            </h2>
            <div class="form-pay-info-row">
                <span>Чтобы выполнять заказы, пройдите верификация</span>
            </div>
            <div class="form-text-why-no-registration">
                <login-widget
                    v-if="supplierStore.telegramVerificationRequired"
                    bot-username="TopupmeKzBot"
                    :redirect-url="supplierStore.telegramReturnRedirectUrl"
                />
                <button
                    v-else
                    class="form-button-submit"
                    @click="handleRedirect"
                >
                    Далее
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { LoginWidget } from 'vue-tg'

import { useGlobalStore } from '@/stores/global';
import { useSupplierStore } from '@/stores/supplier';
import { COMPONENT_NAME } from '@/stores/types';

export default defineComponent({
  components: {
    LoginWidget
  },
  setup () {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();

    return {
      supplierStore,
      globalStore
    };
  },
  methods: {
    handleRedirect() {
      window.open(this.supplierStore.redirectUrl)
      this.globalStore.setShowPopup(COMPONENT_NAME.FORM_LOGIN)
    }
  }
});
</script>

<style lang="less">


</style>
