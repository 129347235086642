import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_popup_request_pay = _resolveComponent("popup-request-pay")!
  const _component_popup_request_success = _resolveComponent("popup-request-success")!
  const _component_popup_request_check = _resolveComponent("popup-request-check")!
  const _component_popup_supplier_error = _resolveComponent("popup-supplier-error")!
  const _component_supplier_request_available_tables = _resolveComponent("supplier-request-available-tables")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_supplier_request_processed_tables = _resolveComponent("supplier-request-processed-tables")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.globalStore.showPopup.requestPay)
      ? (_openBlock(), _createBlock(_component_popup_request_pay, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.globalStore.showPopup.requestPaySuccess)
      ? (_openBlock(), _createBlock(_component_popup_request_success, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.globalStore.showPopup.requestCheck)
      ? (_openBlock(), _createBlock(_component_popup_request_check, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.globalStore.showPopup.errorRequest)
      ? (_openBlock(), _createBlock(_component_popup_supplier_error, { key: 3 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_tabs, {
        modelValue: _ctx.activeName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event)),
        class: "demo-tabs"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            label: "Список заявок",
            name: "available"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_supplier_request_available_tables, {
                requests: _ctx.supplierStore.requests.available,
                active: _ctx.activeName === 'available'
              }, null, 8, ["requests", "active"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, {
            label: "Выполненные заявки",
            name: "processed"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_supplier_request_processed_tables, {
                requests: _ctx.supplierStore.requests.processed,
                active: _ctx.activeName === 'processed'
              }, null, 8, ["requests", "active"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}