<template>
    <div

        class="template-table"
    >
        <div

            class="template-table-wrap"
        >
            <div
                v-if="!requests.length"
                class="template-empty-table-wrap"
            >
                <img
                    src="@/assets/icon_empty_table.svg"
                    class="template-empty-table-icon"
                    alt="empty-table-icon"
                >
                <div class="template-empty-table-text">
                    Нет активных заявок
                </div>
            </div>
            <table v-if="requests.length">
                <thead>
                    <tr>
                        <th>Сумма</th>
                        <th>Доход</th>
                        <th class="time">
                            Оставшееся время
                        </th>
                        <th>&#x20;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="request in requests"
                        :key="request.topupId"
                    >
                        <td>{{ request.amountUsd.toFixed(2) }} {{ currencySymbol }}</td>
                        <td class="price">
                            {{ (request.amountUsd * 0.05).toFixed(2) }} {{ currencySymbol }}
                        </td>
                        <td>{{ timerData(request.creationDate) }}</td>
                        <td>
                            <button @click="handlePayRequest(request)">
                                Оплатить
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td
                            colspan="4"
                            class="template-table-paginator"
                        >
                            <!--                            <paginator-->
                            <!--                                :total-pages="100"-->
                            <!--                                :per-page="10"-->
                            <!--                                :current-page="currentPage"-->
                            <!--                                @pagechanged="onPageChange"-->
                            <!--                            />-->
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global';

import { TopUp } from '@/types/TopUp';
import { useSupplierStore } from '@/stores/supplier';
import { COMPONENT_NAME } from '@/stores/types';

import { currencySymbol } from '@/utils';
import { decodeHtmlEntity } from '@/utils/html_entity';

import Paginator from '@/components/Paginator.vue';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export default defineComponent({
  components: {
    Paginator
  },
  props: {
    requests: {
      type: Array as PropType<TopUp[]>,
      required: true
    },
    active: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup () {
    const globalStore = useGlobalStore();
    const supplierStore = useSupplierStore();

    supplierStore.requestsAvailable()

    return {
      globalStore,
      supplierStore
    };
  },
  data() {
    return {
      currentPage: 1,
      currentTime: Date.now(),
      setTimeoutId: undefined as number | undefined
    }
  },
  computed: {
    currencySymbol() {
      return decodeHtmlEntity(currencySymbol[ 'USD' ]);
    },
    timerData() {
      return (time: number) => {
        const diffTime = (time * 1000) - this.currentTime;
        const duration = dayjs.duration(diffTime, 'milliseconds');
        const twoDP = (n: number, t: boolean = true) => (n <= 9 ? `0${n}${t ? ':' : ''}` : `${n}${t ? ':' : ''}`);

        // return `${twoDP(duration.days())}${twoDP(duration.hours())}${twoDP(duration.minutes())}${twoDP(duration.seconds(), false)}`;
        return `${twoDP(duration.minutes())}${twoDP(duration.seconds(), false)}`;
      };
    }
  },
  watch: {
    active(val) {
      if(val) {
        this.setTimeoutId = setInterval(this.updateCurrenTime, 1000);
      } else {
        clearInterval(this.setTimeoutId);
      }
    }
  },
  mounted() {
    this.setTimeoutId = setInterval(this.updateCurrenTime, 1000);
  },
  beforeUnmount() {
    clearInterval(this.setTimeoutId);
  },
  methods: {
    onPageChange(page: number) {
      console.log(page)
      this.currentPage = page;
    },
    async updateCurrenTime() {
      this.currentTime = Date.now();
      await this.supplierStore.requestsAvailable();
    },
    async handlePayRequest(request: TopUp) {
      this.supplierStore.setActiveRequest(request);
      this.supplierStore.requestsAvailableReserve(request.topupId);
      this.globalStore.setShowPopup(COMPONENT_NAME.REQUEST_PAY);
    }
  }
});
</script>

<style lang="less">
@import "~@/styles/table.less";
</style>
