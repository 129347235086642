<template>
    <div
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <div class="form-text-status-title">
                Спасибо
            </div>
            <div class="form-text-status-description">
                Вопрос отправлен в службу поддержки, ожидайте ответ на почту в течение нескольких часов.
            </div>
            <button
                class="form-button-submit"
                @click="goToHome"
            >
                Спасибо
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useClientStore } from '@/stores/client';
import { useGlobalStore } from '@/stores/global';
import dayjs from 'dayjs';
import { COMPONENT_NAME } from '@/stores/types';

export default defineComponent({
  setup () {
    const globalStore = useGlobalStore();
    return {
      setShowPopup: globalStore.setShowPopup
    };
  },
  methods: {
    async goToHome() {
      if(this.$route.meta.requiresAuth) {
        this.$router.push('/supplier')
      } else {
        this.$router.push('/steam/#form_pay')
      }
   }
  }


});
</script>
