<template>
    <div
        class="form-fly-wrap"
    >
        <div

            class="form-content"
        >
            <h2>
                Чек заявки
            </h2>
            <div class="form-pay-info-row">
                <span>Тип</span>
                <span>{{ convertType(supplierStore.activeWallet.type) }}</span>
            </div>
            <div class="form-pay-info-row">
                <span>Сумма</span>
                <span>{{ supplierStore.activeWallet.amount.toFixed(2) }} &#8376;</span>
            </div>
            <div class="form-pay-info-row">
                <span>Статус</span>
                <span class="price">{{ convertStatus(supplierStore.activeWallet.status) }} </span>
            </div>
            <div class="form-pay-info-row">
                <span>Дата выполнения</span>
                <span>{{ convertTime(supplierStore.activeWallet.date) }}</span>
            </div>
            <button
                class="form-button-submit"
                @click="hideAll"
            >
                Ок
            </button>
        </div>
    </div>
</template>

<script lang="ts">

import { useGlobalStore } from '@/stores/global';
import { defineComponent } from 'vue';
import { useSupplierStore } from '@/stores/supplier';
import dayjs from 'dayjs';
import { WalletStatus, WalletType } from '@/types/Wallet';

export default defineComponent({
  setup () {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();

    return {
      supplierStore,
      globalStore
    };
  },
  computed: {
    convertTime() {
      return (time: string) => {
        return dayjs(time).format('HH:mm DD.MM.YYYY');
      };
    },
    convertType () {
      return (type: keyof typeof WalletType) => {
        return WalletType[ type ];
      };
    },
    convertStatus () {
      return (type: keyof typeof WalletStatus) => {
        return WalletStatus[ type ];
      };
    }
  },
  methods: {
    async hideAll() {
      this.globalStore.hideAll()
    }
  }
});
</script>

<style lang="less">

</style>
