import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_popup_expired_registration = _resolveComponent("popup-expired-registration")!
  const _component_popup_done_registration = _resolveComponent("popup-done-registration")!
  const _component_popup_failed_registration = _resolveComponent("popup-failed-registration")!
  const _component_popup_error = _resolveComponent("popup-error")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.status === 'expired')
      ? (_openBlock(), _createBlock(_component_popup_expired_registration, { key: 0 }))
      : (_ctx.status === 'done')
        ? (_openBlock(), _createBlock(_component_popup_done_registration, { key: 1 }))
        : (_ctx.status === 'failed')
          ? (_openBlock(), _createBlock(_component_popup_failed_registration, { key: 2 }))
          : (_openBlock(), _createBlock(_component_popup_error, { key: 3 }))
  ]))
}