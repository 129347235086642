<template>
    <div class="home-content">
        <form-restore-password v-if="globalStore.showPopup.formRestorePassword" />
        <popup-restore-password-error v-if="globalStore.showPopup.errorRestorePassword" />
        <popup-error v-else-if="globalStore.showPopup.errorRequest" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global';

import PopupError from '@/components/popup/PopupError.vue';
import PopupRestorePasswordError from '@/components/popup/PopupRestorePasswordError.vue';
import FormRestorePassword from '@/components/form/FormRestorePassword.vue';
import { useSupplierStore } from '@/stores/supplier';
import { COMPONENT_NAME } from '@/stores/types';

export default defineComponent({
  components: {
    FormRestorePassword,
    PopupRestorePasswordError,
    PopupError
  },
  setup () {
    const globalStore = useGlobalStore();
    const supplierStore = useSupplierStore();
    return {
      supplierStore,
      globalStore
    };
  },
  async created() {
    if(this.$route.params.id) {
      this.globalStore.setShowPopup(COMPONENT_NAME.FORM_RESTORE_PASSWORD)
    } else {
      this.$router.push('/supplier/auth/#form_login')
    }
  }

});
</script>

<style lang="less">

</style>
