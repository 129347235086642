<template>
    <div
        class="form-fly-wrap"
    >
        <div
            class="form-content"
        >
            <img
                class="form-icon-success"
                src="@/assets/icon_popup_success.svg"
                alt=""
            >
            <div class="form-text-status-title">
                Успех!
            </div>
            <div class="form-text-status-description">
                Средства поступят на вашу карту в течении нескольких минут
            </div>
            <button
                class="form-button-submit"
                @click="closePopup"
            >
                OK
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useClientStore } from '@/stores/client';
import { useGlobalStore } from '@/stores/global';
import dayjs from 'dayjs';
import { useSupplierStore } from '@/stores/supplier';

export default defineComponent({
  setup() {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();

    return {
      supplierStore,
      globalStore
    };
  },
  methods: {
    closePopup() {
      this.globalStore.hideAll()
    }
  }
});
</script>

<style lang="less">
.form-icon-success {
  width: 120px;
  margin: 0 auto;
}
</style>
