<template>
    <div
        id="form_support"
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <h2>
                Задать вопрос TopUpMe
            </h2>

            <h3>
                Мы ответим на указанный email по будням c 10:00 до 19:00
            </h3>
            <div class="form-error-message">
                {{ errorMessage }}
            </div>
            <div class="form-field">
                <div class="form-field-content">
                    <div class="form-field-label">
                        Ваш вопрос
                    </div>
                    <textarea
                        v-model="message"
                        :class="{'shake' : messageAnimation}"
                        placeholder="Здравствуйте! Подскажите пожалуйста ..."
                    />
                </div>
            </div>

            <div class="form-field">
                <div class="form-field-content">
                    <div class="form-field-label">
                        Email
                    </div>
                    <input
                        v-model="replyEmail"
                        :class="{'shake' : replyEmailAnimation}"
                        type="text"
                        placeholder="someone@example.com"
                    >
                </div>
                <div class="form-field-help">
                    <div class="form-field-help-title">
                        Проверьте email адрес
                    </div>
                    По введенному email служба поддержки свяжется с вами.
                </div>
                <div class="form-field-icon icon-help" />
            </div>
            <vue-recaptcha
                ref="recaptcha"
                class="form-captcha"
                :class="{'shake' : captchaAnimation}"
                sitekey="6LdcM9opAAAAADbrHF-YFhrH1qZBbhMbbGbuFP5l"
                theme="dark"
                @verify="handleSuccessCaptcha"
            />
            <button
                class="form-button-submit"
                @click="() => { handleSuccess() }"
            >
                Задать вопрос
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
import { VueRecaptcha } from 'vue-recaptcha';
import { useClientStore } from '@/stores/client';
import { useGlobalStore } from '@/stores/global';
import { COMPONENT_NAME } from '@/stores/types';

export default defineComponent({
  components: { VueRecaptcha },
  setup () {
    const store = useClientStore();
    const globalStore = useGlobalStore();
    const recaptcha = ref<VueRecaptcha | null>(null)

    return {
      recaptcha,
      store,
      globalStore
    };
  },
  data() {
    return {
      messageAnimation: false,
      replyEmailAnimation: false,
      captchaAnimation: false,
      errorMessage: '',
      message: '',
      replyEmail: '',
      greCaptchaResponse: ''
    }
  },
  methods: {
    async handleSuccessCaptcha(greCaptchaResponse: string) {
      this.greCaptchaResponse = greCaptchaResponse;
    },
    async handleSuccess() {
      this.messageAnimation = false;
      this.replyEmailAnimation = false;
      this.captchaAnimation = false;

      await new Promise(res => setTimeout(res, 100));

      const { message, replyEmail, greCaptchaResponse } = this;

      if(!this.message) {
        this.messageAnimation = true;
      } else if(!/^\S+@\S+\.\S+$/.test(this.replyEmail)) {
        this.replyEmailAnimation = true;
      } else if(this.greCaptchaResponse === '') {
        this.captchaAnimation = true;
      } else {
        const { success, errorMessage } = await this.store.supportMessage({ message, replyEmail, greCaptchaResponse })

        if(success) {
          this.message = '';
          this.replyEmail = '';
          this.errorMessage = '';
          this.greCaptchaResponse = '';
          if(this.recaptcha) {
            this.recaptcha.reset()
          }
          this.globalStore.setShowPopup(COMPONENT_NAME.SUCCESS_SUPPORT)
        } else {
          this.errorMessage = errorMessage;
        }
      }
    }
  }
});
</script>

<style lang="less">
.form-captcha {
  margin: 20px auto 0;
}
</style>
