<template>
    <div

        class="template-table"
    >
        <div

            class="template-table-wrap"
        >
            <div
                v-if="!requests.length"
                class="template-empty-table-wrap"
            >
                <img
                    src="@/assets/icon_empty_table.svg"
                    class="template-empty-table-icon"
                    alt="empty-table-icon"
                >
                <div class="template-empty-table-text">
                    Нет выполненных заявок
                </div>
            </div>
            <table v-if="requests.length">
                <thead>
                    <tr>
                        <th>Сумма</th>
                        <th>Доход</th>
                        <th class="time">
                            Дата
                        </th>
                        <th>&#x20;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="request in requests"
                        :key="request.topupId"
                    >
                        <td>{{ request.amountUsd.toFixed(2) }} {{ currencySymbol }}</td>
                        <td class="price">
                            {{ (request.amountUsd * 0.05).toFixed(2) }} {{ currencySymbol }}
                        </td>
                        <td>{{ convertTime(request.creationDate) }}</td>
                        <td>
                            <button @click="handlePayRequest(request)">
                                Чек операции
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td
                            colspan="4"
                            class="template-table-paginator"
                        >
                            <!--                            <paginator-->
                            <!--                                :total-pages="100"-->
                            <!--                                :per-page="10"-->
                            <!--                                :current-page="currentPage"-->
                            <!--                                @pagechanged="onPageChange"-->
                            <!--                            />-->
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global';

import Paginator from '@/components/Paginator.vue';

import { TopUp } from '@/types/TopUp';
import { useSupplierStore } from '@/stores/supplier';
import { decodeHtmlEntity } from '@/utils/html_entity';
import { currencySymbol } from '@/utils';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { COMPONENT_NAME } from '@/stores/types';
dayjs.extend(duration);

export default defineComponent({
  components: {
    Paginator
  },
  props: {
    requests: {
      type: Array as PropType<TopUp[]>,
      required: true
    }
  },
  setup () {
    const globalStore = useGlobalStore();
    const supplierStore = useSupplierStore();
    supplierStore.requestsProcessed()
    return {
      globalStore,
      supplierStore
    };
  },
  data() {
    return {
      currentPage: 1
    }
  },
  computed: {
    convertTime() {
      return (time: number) => {
        return dayjs(time).format('DD.MM.YYYY HH:mm');
      };
    },
    currencySymbol() {
      return decodeHtmlEntity(currencySymbol[ 'KZT' ]);
    }
  },
  methods: {
    onPageChange(page: number) {
      this.currentPage = page;
    },
    handlePayRequest(request: TopUp) {
      this.globalStore.setShowPopup(COMPONENT_NAME.REQUEST_CHECK)
      this.supplierStore.setActiveRequest(request)
    }
  }
});
</script>

<style lang="less">
@import "~@/styles/table.less";
</style>
