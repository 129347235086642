<template>
    <div class="home-content">
        <last-top-up />
        <popup-success v-if="globalStore.showPopup.successPay" />
        <popup-pay-error v-else-if="globalStore.showPopup.errorPay" />
        <popup-loading v-else-if="globalStore.showPopup.loadingPay" />
        <popup-error v-else-if="globalStore.showPopup.errorRequest" />
    </div>
    <f-a-g />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useClientStore } from '@/stores/client'
import { useGlobalStore } from '@/stores/global';

import PopupSuccess from '@/components/popup/PopupSuccess.vue';
import PopupError from '@/components/popup/PopupError.vue';
import PopupPayError from '@/components/popup/PopupPayError.vue';
import PopupLoading from '@/components/popup/PopupLoading.vue';
import LastTopUp from '@/components/LastTopUp.vue';
import FAG from '@/components/FAG.vue';
import { Status } from '@/types/TopUp';

export default defineComponent({
  components: {
    PopupSuccess,
    PopupError,
    PopupPayError,
    PopupLoading,
    LastTopUp,
    FAG
  },
  setup () {
    const globalStore = useGlobalStore();
    const store = useClientStore();
    return {
      store,
      globalStore
    };
  },
  data() {
    return {
      setTimeoutId: undefined as number | undefined
    }
  },
  async created() {
    if(window.parent) {
      window.parent.postMessage({
        type: 'topUpStatusPage',
        id: this.$route.params.id
      });
    }

    await this.checkStatus();
  },
  beforeUnmount() {
    clearTimeout(this.setTimeoutId);
  },
  methods: {
    async checkStatus () {
      clearTimeout(this.setTimeoutId);

      await this.store.getTopUpForId(this.$route.params.id)

      if(this.store.currentTopUp) {
        switch (this.store.currentTopUp.status) {
          case Status.DONE:
            this.globalStore.setShowPopup('successPay')
            break;
          case Status.WAITING_FOR_PAYMENT:
          case Status.WAITING_FOR_PROCESSING:
          case Status.STATUS_PENDING:
            this.globalStore.setShowPopup('loadingPay')
            this.reCheckStatus()
            break;
          default:
            // case Status.NEW:
            // case Status.FAILED:
            // case Status.CHECKED:
            // case Status.CHECK_FAILED:
            // case Status.PAYMENT_FAILED:
            this.globalStore.setShowPopup('errorPay')
            break;
        }
      }
    },
    reCheckStatus() {
      this.setTimeoutId = setTimeout(async () => {
        await this.checkStatus();
      }, 10 * 1000);
    }
  }
});
</script>

<style lang="less">

</style>
