<template>
    <div
        id="form_restore_email"
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <h2>
                Восстановление пароля
            </h2>
            <div class="form-field">
                <div class="form-field-content">
                    <div class="form-field-label">
                        Email
                    </div>
                    <input
                        v-model="email"
                        :class="{'shake' : emailAnimation}"
                        type="text"
                        placeholder="example@email.com"
                    >
                </div>
            </div>

            <button
                class="form-button-submit"
                @click="handleSuccess"
            >
                Восстановить пароль
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useGlobalStore } from '@/stores/global';
import { useSupplierStore } from '@/stores/supplier';
import { COMPONENT_NAME } from '@/stores/types';
import { REGEX_CHECK_EMAIL } from '@/utils/constant';

export default defineComponent({
  setup () {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();
    return {
      supplierStore,
      globalStore
    };
  },
  data() {
    return {
      emailAnimation: false,
      email: ''
    }
  },
  methods: {
    async handleSuccess() {
      this.emailAnimation = false;

      await new Promise(res => setTimeout(res, 100));

      const { email } = this;

      if(!REGEX_CHECK_EMAIL.test(email)) {
        this.emailAnimation = true;
      } else {
        const { success, errorMessage } = await this.supplierStore.restore({ email })

        if(success) {
          this.email = '';
          this.globalStore.setShowPopup(COMPONENT_NAME.SUCCESS_RESTORE_EMAIL)
        } else {
          this.globalStore.setErrorMessage(errorMessage)
          this.globalStore.setShowPopup(COMPONENT_NAME.ERROR_RESTORE_PASSWORD)
        }
      }
    }
  }
});
</script>

<style lang="less">

</style>
