import { defineStore } from 'pinia';
import { getWalletBalance, postDataLogin, postDataRegistration, postDataRestoreEmail, postWalletHistory } from '@/api';
import { getRequestsAvailable, getRequestsAvailableReserve, getRequestsProcessed, getRequestsReservedCancel, getRequestsReservedProcess } from '@/api/request';
import { useGlobalStore } from '@/stores/global';
import { getProfileVerification } from '@/api/profile';
import {
    getDataPayoutDestination,
    getDataPayoutLimits,
    getDataPayoutsCreate,
    postDataPayoutDestinationAdd,
    postDataPayoutDestinationRemove,
    postDataRestorePassword
} from '@/api/supplier';
import {
    RequestPayoutDestinationAdd,
    RequestPayoutDestinationRemove,
    RequestPayoutsCreate,
    RequestRestorePassword
} from '@/api/supplier/types';
import {
    RequestLogin,
    RequestRegistration,
    RequestWalletHistory
} from '@/api/types';
import { TopUp } from '@/types/TopUp';
import { Wallet } from '@/types/Wallet';

import { logout } from '@/main';

import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';

export const useSupplierStore = defineStore({
    id: 'supplierStore',
    state: (): any =>
        ({
            authToken: '',
            verified: false,
            redirectUrl: '',
            telegramReturnRedirectUrl: '',
            telegramVerificationRequired: false,
            payoutDestinations: [],
            payoutLimits: {
                amountMin: 0,
                amountMax: 100,
                totalAmountDayMax: 500
            },
            activeRequest: {
                topupId: '',
                status: null,
                amountUsd: 0,
                account: '',
                paymentUrl: '',
                errorDescription: '',
                creationDate: 0,
                processedDate: 0
            },
            activeWallet: {
                date: '',
                type: '',
                amount: 0,
                status: ''
            },
            requests:{
                available: [],
                processed: []
            },
            wallet: {
                balance: 0,
                history: []
            }
        }),
    getters: {
        isAuth(state) {
            if(state.authToken) {
                const { exp } = jwtDecode(state.authToken) as {exp: number};

                if(exp * 1000 < Date.now()) { // если старый то выходим и дропаем данные
                    this.logout()
                    return false;
                }
                return state.verified; // Пускать только проверенных
            }

            return false;
        }
    },
    actions: {
        async setAuthToken(authToken: string) {
            this.authToken = authToken;
        },
        async setActiveRequest(request: TopUp) {
            this.activeRequest = request;
        },
        async setActiveWallet(wallet: Wallet) {
            this.activeWallet = wallet;
        },
        async login(param: RequestLogin) {
            try {
                const { token, success, errorMessage } = await postDataLogin(param);
                if(success) {
                    this.authToken = token;
                    localStorage.setItem('authToken', token)
                }
                return { success, errorMessage };
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();

                if(/403/.test(e as string)) {
                    await this.logout()
                }
            }
        },
        async logout() {
            this.authToken = '';
            localStorage.removeItem('authToken');
            logout();
        },
        async registration(param: RequestRegistration) {
            try {
                const { token, success, errorMessage } = await postDataRegistration(param);
                if(success) {
                    this.authToken = token;
                }
                return { success, errorMessage };
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();

                if(/403/.test(e as string)) {
                    await this.logout()
                }
            }
        },
        async restore(param: RequestRegistration) {
            try {
                const { token, success, errorMessage } = await postDataRestoreEmail(param);
                if(success) {
                    this.authToken = token;
                }
                return { success, errorMessage };
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();

                if(/403/.test(e as string)) {
                    await this.logout()
                }
            }
        },
        async restorePassword(param: RequestRestorePassword) {
            try {
                return await postDataRestorePassword(param);
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async requestsAvailable() {
            try {
                const { list } = await getRequestsAvailable({
                    token: this.authToken
                });

                if(list) {
                    this.requests.available = list.sort((a, b) => a.creationDate - b.creationDate).map(request => {
                        // request.creationDate += 3600 * 24 * 15.13; // TODO: Временно для разработки
                        request.creationDate += 60 * 15; // Корректируем время для таймера
                        return request;
                    }).reduce((accum: TopUp[], request: TopUp) => {
                        if(Date.now() < request.creationDate * 1000) { // Отсекаем старые заявки
                            return [...accum, request];
                        }

                        return accum;
                    }, []);
                }
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();

                if(/403/.test(e as string)) {
                    await this.logout()
                }
            }
        },
        async requestsProcessed() {
            try {
                const dateFrom = dayjs().startOf('month').format();
                const dateTo = dayjs().endOf('month').format();

                const { list } = await getRequestsProcessed({
                    token: this.authToken,
                    dateFrom,
                    dateTo
                });
                if(list) {
                    this.requests.processed = list.sort((a, b) => a.processedDate - b.processedDate);
                }
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async walletHistory({ dateFrom, dateTo }: RequestWalletHistory) {
            try {
                const { entries } = await postWalletHistory({
                    token: this.authToken,
                    dateFrom,
                    dateTo
                });
                if(entries) {
                    this.wallet.history = entries;
                }
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async walletBalance() {
            try {
                const { balanceAmount } = await getWalletBalance({
                    token: this.authToken
                });
                if(balanceAmount) {
                    this.wallet.balance = balanceAmount;
                }
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();

                if(/403/.test(e as string)) {
                    await this.logout()
                }
            }
        },
        async profileVerification() {
            try {
                const { verified, token, telegramReturnRedirectUrl, telegramVerificationRequired, redirectUrl } = await getProfileVerification({
                    token: this.authToken
                });

                if(verified) {
                    this.authToken = token;
                    localStorage.setItem('authToken', token)
                }

                this.redirectUrl = telegramReturnRedirectUrl;
                this.telegramReturnRedirectUrl = telegramReturnRedirectUrl;
                this.telegramVerificationRequired = telegramVerificationRequired;
                this.verified = verified;
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();

                if(/403/.test(e as string)) {
                    await this.logout()
                }
            }
        },
        async requestsAvailableReserve(topupId: string) {
            try {
                const { success, errorMessage } = await getRequestsAvailableReserve({
                    token: this.authToken,
                    topupId
                });

                if(!success) {
                    const globalStore = useGlobalStore();
                    globalStore.setErrorMessage(errorMessage);
                    globalStore.showErrorRequestPopup();
                }
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();

                if(/403/.test(e as string)) {
                    await this.logout()
                }
            }
        },
        async requestsAvailableCancel(topupId: string) {
            try {
                const { success, errorMessage } = await getRequestsReservedCancel({
                    token: this.authToken,
                    topupId
                });

                if(!success) {
                    const globalStore = useGlobalStore();
                    globalStore.setErrorMessage(errorMessage);
                    globalStore.showErrorRequestPopup();
                }
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();

                if(/403/.test(e as string)) {
                    await this.logout()
                }
            }
        },
        async requestsReservedProcess(topupId: string) {
            try {
                return await getRequestsReservedProcess({
                    token: this.authToken,
                    topupId
                });

            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();

                if(/403/.test(e as string)) {
                    await this.logout()
                }
            }
        },
        async requestsPayoutDestination() {
            try {
                const { payoutDestinations } =  await getDataPayoutDestination({
                    token: this.authToken
                });
                this.payoutDestinations = payoutDestinations;
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();

                if(/403/.test(e as string)) {
                    await this.logout()
                }
            }
        },
        async requestsPayoutLimits() {
            try {
                this.payoutLimits = await getDataPayoutLimits({
                    token: this.authToken
                });
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();

                if(/403/.test(e as string)) {
                    await this.logout()
                }
            }
        },
        async requestsPayoutsCreate({ destinationId, amount }: RequestPayoutsCreate) {
            try {
                return await getDataPayoutsCreate({
                    token: this.authToken,
                    destinationId,
                    amount
                });
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async requestsPayoutDestinationRemove({ destinationId }: RequestPayoutDestinationRemove) {
            try {
                return await postDataPayoutDestinationRemove({
                    token: this.authToken, destinationId
                });
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async requestsPayoutDestinationAdd({ name }: RequestPayoutDestinationAdd) {
            try {
                return await postDataPayoutDestinationAdd({
                    token: this.authToken,
                    name
                });
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        }
    }
});
