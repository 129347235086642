<template>
    <div
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <img
                class="form-icon-error"
                src="@/assets/icon_popup_error.svg"
                alt=""
            >
            <div class="form-text-status-title">
                Регистрация не успешна
            </div>
            <div class="form-text-status-description">
                Возможно вы уже были зарегистрированы <br>
                Войдите или зарегистрируйтесь заново
            </div>
            <button class="form-button-submit">
                <router-link
                    to="/supplier/auth/#form_login"
                >
                    На главную
                </router-link>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useClientStore } from '@/stores/client';
import { useGlobalStore } from '@/stores/global';

export default defineComponent({
  setup() {

  }
});
</script>
