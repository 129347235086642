<template>
    <div class="home-content">
        <popup-error v-if="globalStore.showPopup.errorRequest" />
        <popup-verification v-if="globalStore.showPopup.profileVerification" />
        <popup-done-verification v-if="globalStore.showPopup.profileDoneVerification" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global';

import PopupError from '@/components/popup/PopupError.vue';
import PopupVerification from '@/components/popup/PopupVerification.vue';
import PopupDoneVerification from '@/components/popup/PopupDoneVerification.vue';
import { COMPONENT_NAME } from '@/stores/types';
import { useSupplierStore } from '@/stores/supplier';
export default defineComponent({
  components: {
    PopupError,
    PopupVerification,
    PopupDoneVerification
  },
  setup () {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();
    return {
      supplierStore,
      globalStore
    };
  },
  async mounted() {
    await this.supplierStore.profileVerification()

    if(this.supplierStore.verified) {
      this.globalStore.setShowPopup(COMPONENT_NAME.PROFILE_DONE_VERIFICATION)
    } else {
      this.globalStore.setShowPopup(COMPONENT_NAME.PROFILE_VERIFICATION)
    }
  }
});
</script>
