<template>
    <div
        id="form_registration"
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <h2>
                Обновить пароль
            </h2>
            <div class="form-error-message">
                {{ errorMessage ? `${errorMessage} Повторите отправку` : '' }}
            </div>

            <div class="form-field">
                <div class="form-field-content">
                    <div class="form-field-label">
                        Новый пароль
                    </div>
                    <input
                        v-model="password"
                        :class="{'shake' : passwordAnimation}"
                        type="password"
                        placeholder="qwerty12"
                    >
                </div>
                <div class="form-field-help">
                    <div class="form-field-help-title">
                        Введите пароль
                    </div>
                    8 и более символов и как минимум:
                    <ul>
                        <li>одна заглавная и одна строчная буква</li>
                        <li>одна цифра</li>
                        <li>один специальный символ, например: ~!?@#$%^&*</li>
                    </ul>
                </div>
                <div class="form-field-icon icon-help" />
            </div>

            <div class="form-field">
                <div class="form-field-content">
                    <div class="form-field-label">
                        Повторите новый пароль
                    </div>
                    <input
                        v-model="confirmPassword"
                        :class="{'shake' : confirmPasswordAnimation}"
                        type="password"
                        placeholder="qwerty12"
                    >
                </div>
                <div class="form-field-help">
                    <div class="form-field-help-title">
                        Введите пароль
                    </div>
                    8 и более символов и как минимум:
                    <ul>
                        <li>одна заглавная и одна строчная буква</li>
                        <li>одна цифра</li>
                        <li>один специальный символ, например: ~!?@#$%^&*</li>
                    </ul>
                </div>
                <div class="form-field-icon icon-help" />
            </div>

            <button
                class="form-button-submit"
                @click="handleSuccess"
            >
                Обновить пароль
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global';
import { useSupplierStore } from '@/stores/supplier';
import { COMPONENT_NAME } from '@/stores/types';
import { REGEX_CHECK_PASSWORD } from '@/utils/constant';

export default defineComponent({
  setup () {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();
    return {
      supplierStore,
      globalStore
    };
  },
  data() {
    return {
      errorMessage: '',
      passwordAnimation: false,
      confirmPasswordAnimation: false,
      password: '',
      confirmPassword: ''
    }
  },
  methods: {
    async handleSuccess() {
      this.passwordAnimation = false;
      this.confirmPasswordAnimation = false;
      this.errorMessage = '';

      await new Promise(res => setTimeout(res, 100));

      const { password, confirmPassword } = this;

      if(!new RegExp(REGEX_CHECK_PASSWORD).test(password)) {
        this.passwordAnimation = true;
        this.errorMessage = 'Придумайте другой пароль';
      } else if(password !== confirmPassword) {
        this.confirmPasswordAnimation = true;
        this.errorMessage = 'Пароли не совпадают';
      }

      const recoverToken = this.$route.params.id;
      const { success, errorMessage } = await this.supplierStore.restorePassword({ password, recoverToken })

        if(success) {
          this.password = '';
          this.confirmPassword = '';
          this.errorMessage = '';
          this.$router.push('/supplier/auth/#form_login')
        } else {
          this.globalStore.setErrorMessage(errorMessage)
          this.globalStore.setShowPopup(COMPONENT_NAME.ERROR_RESTORE_PASSWORD)
        }
      }
    }
});
</script>

<style lang="less">

</style>
