<template>
    <div
        class="form-fly-wrap"
    >
        <div
            class="form-content"
        >
            <h2>
                Редактирование реквизитов
                <span
                    class="form-close"
                    @click="closePopup"
                />
            </h2>
            <div
                v-for="({ name, destinationId }) in payoutDestinations"
                :key="destinationId"
                class="form-cart-item"
            >
                <div>{{ name }}</div>
                <button
                    class="form-cart-delete"
                    :class="{progress: cartDeleteDestinationId === destinationId}"
                    type="button"
                    @click="cartDelete(destinationId)"
                />
            </div>
            <div
                class="form-open-cart-edit"
                @click="openCardAdd"
            >
                &#43; Добавить новую карту
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { useGlobalStore } from '@/stores/global';
import { defineComponent } from 'vue';
import { useSupplierStore } from '@/stores/supplier';
import dayjs from 'dayjs';
import { COMPONENT_NAME } from '@/stores/types';

export default defineComponent({
  setup () {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();

    return {
      supplierStore,
      globalStore
    };
  },
  data() {
    return {
      cartDeleteDestinationId: ''
    }
  },
  computed: {
    payoutDestinations (): number {
      return this.supplierStore.payoutDestinations;
    }
  },
  mounted() {
    this.supplierStore.requestsPayoutDestination()
  },
  methods: {
    closePopup() {
      this.globalStore.hideAll()
    },
    openCardAdd() {
      this.globalStore.setShowPopup(COMPONENT_NAME.CART_ADD)
    },
    async cartDelete(destinationId: string) {
      if(!this.cartDeleteDestinationId) {
        this.cartDeleteDestinationId = destinationId;
        const { success } = await this.supplierStore.requestsPayoutDestinationRemove({
          destinationId
        })

        if(success) {
          await this.supplierStore.requestsPayoutDestination()
          this.cartDeleteDestinationId = '';
        }
      }

    }
  }
});
</script>

<style lang="less">


</style>
