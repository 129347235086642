<template>
    <div
        class="form-fly-wrap"
    >
        <div
            class="form-content"
        >
            <h2>
                Вывод средств
                <span
                    class="form-close"
                    @click="closePopup"
                />
            </h2>
            <div class="form-error-message">
                {{ errorMessage }}
            </div>
            <template v-if="showStep === 'money'">
                <div class="form-pay-info-row">
                    <span>Доступные средства</span>
                    <span class="price">{{ balance }} &#8376;</span>
                </div>
                <div class="form-field">
                    <div class="form-field-content">
                        <div class="form-field-label">
                            Сумма вывода
                        </div>
                        <input
                            v-model="amount"
                            :class="{'shake' : amountAnimation}"
                            type="text"
                        >
                    </div>
                </div>
                <button
                    class="form-button-submit"
                    @click="openSelectCard"
                >
                    Далее
                </button>
            </template>
            <template v-else-if="showStep === 'selectCard'">
                <div class="form-pay-info-row">
                    <span>Сумма вывода</span>
                    <span>{{ amount }} &#8376;</span>
                </div>
                <br>
                <h2>
                    Выберите карту
                </h2>
                <div
                    v-for="({ name, destinationId }) in payoutDestinations"
                    :key="destinationId"
                    class="form-field-radio"
                >
                    <input
                        :id="`radio_${destinationId}`"
                        v-model="selectDestinationId"
                        :checked="selectDestinationId === destinationId"
                        :value="destinationId"
                        type="radio"
                    >
                    <label
                        :for="`radio_${destinationId}`"
                        class="form-field-label"
                    >
                        {{ name }}
                    </label>
                </div>
                <div
                    class="form-open-cart-edit"
                    @click="openCardList"
                >
                    &#43; Добавить/изменить реквизиты
                </div>
                <button
                    class="form-button-submit"
                    @click="payoutsCreate"
                >
                    Вывести средства
                </button>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { useGlobalStore } from '@/stores/global';
import { defineComponent } from 'vue';
import { useSupplierStore } from '@/stores/supplier';
import dayjs from 'dayjs';
import { COMPONENT_NAME } from '@/stores/types';
import { ResponsePayoutLimits } from '@/api/supplier/types';

export default defineComponent({
  setup () {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();

    return {
      supplierStore,
      globalStore
    };
  },
  data() {
    return {
      amount: 1,
      amountAnimation: false,
      errorMessage: '',
      showStep: 'money',
      selectDestinationId: null
    }
  },
  computed: {
    balance (): number {
      return this.supplierStore.wallet.balance;
    },
    payoutDestinations (): number {
      return this.supplierStore.payoutDestinations;
    },
    payoutLimits (): ResponsePayoutLimits {
      return this.supplierStore.payoutLimits;
    }
  },
  watch: {
    amount(val) {
      if (val < this.payoutLimits.amountMin) {
        this.errorMessage = `Сумма не может быть меньше ${this.payoutLimits.amountMin}`;
      } else if (val > this.payoutLimits.amountMax) {
        this.errorMessage = `Сумма не может превышать лимит в ${this.payoutLimits.amountMax}`;
      } else {
        this.errorMessage = '';
      }
    },
    payoutDestinations(destinations) {
      const [destination] = destinations;

      if (destination) {
        this.selectDestinationId = destination.destinationId;
      }
    }
  },
  async mounted() {
    await this.supplierStore.requestsPayoutDestination();
    await this.supplierStore.requestsPayoutLimits();

    this.amount = this.payoutLimits.amountMin;
  },
  methods: {
    closePopup() {
      this.globalStore.hideAll()
    },
    openSelectCard() {
      if(this.amount >= this.payoutLimits.amountMin && this.amount <= this.payoutLimits.amountMax) {
          this.showStep = 'selectCard'
      }
    },
    openCardList() {
      this.globalStore.setShowPopup(COMPONENT_NAME.CART_LIST)
    },
    async payoutsCreate() {
      const { success } = await this.supplierStore.requestsPayoutsCreate({
        destinationId: this.selectDestinationId,
        amount: this.amount
      })

      if(success) {
        this.globalStore.setShowPopup(COMPONENT_NAME.PAYOUT_SUCCESS)
      } else {
        this.globalStore.setShowPopup(COMPONENT_NAME.PAYOUT_ERROR)
      }
    }
  }
});
</script>

<style lang="less">


</style>
