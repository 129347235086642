<template>
    <div
        class="form-fly-wrap"
    >
        <div
            class="form-content"
        >
            <img
                class="form-icon-error"
                src="@/assets/icon_popup_error.svg"
                alt=""
            >
            <div class="form-text-status-title">
                Упс, всё сломалось...
            </div>
            <div class="form-text-status-description">
                {{ globalStore.errorMessage }}<br>
                Попробуйте начать заново
            </div>
            <button
                class="form-button-submit"
                @click="closePopup"
            >
                Закрыть
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global';

export default defineComponent({
  setup () {
    const globalStore = useGlobalStore();
    return {
      globalStore
    };
  },
  methods: {
    closePopup() {
      this.globalStore.hideAll()
    }
  }
});
</script>

<style lang="less">
.form-icon-error {
  width: 120px;
  margin: 0 auto;
}
</style>
