<template>
    <div
        class="last-top-up-content"
    >
        <div class="last-top-up-title">
            Последние операции
        </div>
        <TransitionGroup
            name="topup"
            tag="div"
            class="last-top-up-scroll"
        >
            <div
                v-for="({ account, amountUsd, processedDate, key }) in store.listTopUpLast"
                :key="key"
                class="last-top-up-item"
            >
                <div class="last-top-up-item-icon" />
                <div class="last-top-up-item-content">
                    <div class="last-top-up-item-top">
                        <span>+{{ amountUsd }}&dollar;</span>
                        {{ account }}
                    </div>
                    <div class="last-top-up-item-bottom">
                        {{ formatTime(processedDate) }}
                    </div>
                </div>
            </div>
        </TransitionGroup>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useClientStore } from '@/stores/client';
import dayjs from 'dayjs';

export default defineComponent({
  setup() {
    const store = useClientStore();

    return {
      store
    };
  },
  data() {
    return {
      setTimeoutId: undefined as number | undefined
    }
  },
  beforeUnmount() {
    clearTimeout(this.setTimeoutId);
  },
  created() {
    this.deleteOrUpdateTopUpList();
  },
  methods: {
    deleteOrUpdateTopUpList() {
      clearTimeout(this.setTimeoutId);

      if (this.store.listTopUpLast.length > 10) {
        this.store.deleteFirstItemTopUpList();
      } else {
        this.store.getTopUpList();
      }

      this.setTimeoutId = setTimeout(() => {
        this.deleteOrUpdateTopUpList();
      }, 2000 + (Math.random() * 3000));
    },
    formatTime(time: number) {
      const date = dayjs(time * 1000);

      return date.format('HH:mm DD.MM.YYYY');
    }
  }
});
</script>

<style lang="less">
//.topup-enter-active,
.topup-leave-active {
  transition: all 2s;
}

//.topup-enter-from,
.topup-leave-to {
  opacity: 0;
  margin-top: -70px !important;
}

.last-top-up-content {
  width: 100%;
  max-width: 350px;
  margin: 24px 24px 24px -374px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  padding-top: 100px;
}

.last-top-up-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  display: none;
}

.last-top-up-scroll {
  height: 420px;
  overflow: hidden;
}

.last-top-up-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin: 0 0 14px 0;
}

.last-top-up-item-icon {
  width: 42px;
  height: 42px;
  background: url("@/assets/icon_coins.svg") center center;
  margin-right: 12px;
}

.last-top-up-item-top {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -1px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  span {
    font-weight: 600;
    font-size: 24px;
    color: #E88102;
    margin-right: 8px;
  }
}

.last-top-up-item-bottom {
  letter-spacing: -1px;
  color: #ACACAC;
}

@media (max-width: 1500px) {
  .last-top-up-content {
    display: none;
  }
}
</style>
