<template>
    <div
        class="form-fly-wrap"
    >
        <div
            class="form-content"
        >
            <img
                class="form-icon-error"
                src="@/assets/icon_popup_error.svg"
                alt=""
            >
            <div class="form-text-status-title">
                Упс..
            </div>
            <div class="form-text-status-description">
                Выплата не успешна <br>
                Средства остались на вашем балансе
            </div>
            <button
                class="form-button-submit"
                @click="closePopup"
            >
                OK
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useSupplierStore } from '@/stores/supplier';
import { useGlobalStore } from '@/stores/global';

export default defineComponent({
  setup() {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();

    return {
      supplierStore,
      globalStore
    };
  },
  methods: {
    closePopup() {
      this.globalStore.hideAll()
    }
  }
});
</script>

<style lang="less">
.form-icon-error {
  width: 120px;
  margin: 0 auto;
}
</style>
