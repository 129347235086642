<template>
    <div
        class="form-fly-wrap"
    >
        <div
            class="form-content"
        >
            <h2>
                Добавить новую карту
                <span
                    class="form-close"
                    @click="closePopup"
                />
            </h2>
            <template v-if="!destinationAddUrl">
                <div
                    class="form-field"
                    :class="{'shake' : nameAnimation}"
                >
                    <div class="form-field-content">
                        <div class="form-field-label">
                            Название карты
                        </div>
                        <input
                            v-model="name"
                            maxlength="50"
                            type="text"
                        >
                    </div>
                    <div class="form-field-help">
                        <div class="form-field-help-title">
                            Введите псевдоним карты
                        </div>
                        Это может быть любое удобное вам название.<br>
                        Например, "Моя карта"<br><br>
                        Пожалуйста, не вводите номер карты. Вы сможете это сделать на следующем шагу.
                    </div>
                    <div class="form-field-icon icon-help" />
                </div>
                <button
                    class="form-button-submit"
                    @click="cardAdd"
                >
                    Продолжить
                </button>
            </template>
            <iframe
                v-else
                class="payment-iframe"
                :src="destinationAddUrl"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { useGlobalStore } from '@/stores/global';
import { defineComponent } from 'vue';
import { useSupplierStore } from '@/stores/supplier';
import dayjs from 'dayjs';
import { COMPONENT_NAME } from '@/stores/types';

export default defineComponent({
  setup () {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();

    return {
      supplierStore,
      globalStore
    };
  },
  data() {
    return {
      name: '',
      nameAnimation: false,
      destinationAddUrl: ''
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveIframeMessage)
  },
  beforeUnmount() {
    window.removeEventListener('message', this.receiveIframeMessage)
  },
  methods: {
    closePopup() {
      this.globalStore.hideAll()
    },
    async cardAdd() {
      this.nameAnimation = false;

      await new Promise(res => setTimeout(res, 100));
      if(this.name.length && this.name.length <= 50) {
        const { destinationAddUrl } = await this.supplierStore.requestsPayoutDestinationAdd({
          name: this.name
        })
        this.destinationAddUrl = destinationAddUrl;
      } else {
        this.nameAnimation = true;
      }
    },
    receiveIframeMessage (event: any) {
      if(event.data && event.data.type === 'supplierCardAdd') {
        this.destinationAddUrl = '';
        this.globalStore.setShowPopup(COMPONENT_NAME.CART_LIST)
      }
    }
  }
});
</script>

<style lang="less">


</style>
