export enum Status {
    NEW = 'NEW',
    CHECKED = 'CHECKED',
    CHECK_FAILED = 'CHECKED',
    WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
    WAITING_FOR_PROCESSING = 'WAITING_FOR_PROCESSING',
    STATUS_PENDING = 'STATUS_PENDING',
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    FAILED = 'FAILED',
    DONE = 'DONE'
}

export interface TopUp {
    topupId: string, // ID заявки на пополнение
    status: Status.CHECKED, // Статус запроса
    amountUsd: number, // Сумма пополнения в USD
    account: string, // Логин для пополнения
    paymentUrl: string, // Url для совершения платежа, если status = WAITING_FOR_PAYMENT
    errorDescription: string, // Комментарий, если status = FAILED
    creationDate: number, // Дата создания unixtimestamp
    processedDate: number // Дата исполнения unixtimestamp
}
