<template>
    <div
        id="form_login"
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <h2>
                Вход
            </h2>
            <div class="form-error-message">
                {{ errorMessage }}
            </div>
            <div class="form-field">
                <div class="form-field-content">
                    <div class="form-field-label">
                        Email
                    </div>
                    <input
                        v-model="email"
                        :class="{'shake' : emailAnimation}"
                        type="text"
                        placeholder="example@email.com"
                    >
                </div>
            </div>

            <div class="form-field">
                <div class="form-field-content">
                    <div class="form-field-label">
                        Password
                    </div>
                    <input
                        v-model="password"
                        :class="{'shake' : passwordAnimation}"
                        type="password"
                        placeholder="qwerty12"
                    >
                </div>
            </div>

            <div class="form-text-restore-password">
                <router-link
                    to="/supplier/auth/#form_restore_email"
                >
                    Забыли пароль?
                </router-link>
            </div>

            <button
                class="form-button-submit"
                @click="handleSuccess"
            >
                Войти
            </button>

            <div class="form-text-why-no-registration">
                Нет аккаунта?
                <router-link
                    to="/supplier/auth/#form_registration"
                >
                    Регистрация
                </router-link>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global';
import { useSupplierStore } from '@/stores/supplier';
import { COMPONENT_NAME } from '@/stores/types';

export default defineComponent({
  setup () {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();
    return {
      supplierStore,
      globalStore
    };
  },
  data() {
    return {
      errorMessage: '',
      passwordAnimation: false,
      emailAnimation: false,
      password: '',
      email: ''
    }
  },
  methods: {
    async handleSuccess() {
      this.passwordAnimation = false;
      this.emailAnimation = false;

      await new Promise(res => setTimeout(res, 100));

      const { password, email } = this;

      if(password.length < 8) {
        this.passwordAnimation = true;
      } else if(!/^\S+@\S+\.\S+$/.test(email)) {
        this.emailAnimation = true;
      } else {
        const { success, errorMessage } = await this.supplierStore.login({ password, email })

        if(success) {
          this.password = '';
          this.email = '';

          await this.supplierStore.profileVerification()

          if(this.supplierStore.verified) {
            this.$router.push('/supplier')
          } else {
            //   this.$router.push('/supplier/profile/verification')
            this.globalStore.setShowPopup(COMPONENT_NAME.PROFILE_VERIFICATION)
          }
        } else {
          this.errorMessage = errorMessage;
        }
      }
    }
  }
});
</script>

<style lang="less">

</style>
