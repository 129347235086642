<template>
    <div
        class="form-fly-wrap"
    >
        <div

            class="form-content"
        >
            <h2>
                Чек заявки
            </h2>
            <div class="form-pay-info-row">
                <span>ID</span>
                <span>{{ supplierStore.activeRequest.topupId }}</span>
            </div>
            <div class="form-pay-info-row">
                <span>Сумма пополнения</span>
                <span>{{ amount }} &#8376;</span>
            </div>
            <div class="form-pay-info-row">
                <span>Доход</span>
                <span>{{ income }} &#8376;</span>
            </div>
            <div class="form-pay-info-row">
                <span>Зачислено на Ваш счет</span>
                <span class="price">{{ credited }} &#8376;</span>
            </div>
            <div class="form-pay-info-row">
                <span>Дата выполнения</span>
                <span>{{ convertTime(supplierStore.activeRequest.processedDate) }}</span>
            </div>
            <button
                class="form-button-submit"
                @click="hideAll"
            >
                Ок
            </button>
        </div>
    </div>
</template>

<script lang="ts">

import { useGlobalStore } from '@/stores/global';
import { defineComponent } from 'vue';
import { useSupplierStore } from '@/stores/supplier';
import dayjs from 'dayjs';

export default defineComponent({
  setup () {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();

    return {
      supplierStore,
      globalStore
    };
  },
  computed: {
    convertTime() {
      return (time: number) => {
        return dayjs(time).format('DD.MM.YYYY HH:mm');
      };
    },
    amount(): number {
      return Number(this.supplierStore.activeRequest.amountUsd.toFixed(2));
    },
    income(): number {
      return Number((this.supplierStore.activeRequest.amountUsd * 0.05).toFixed(2));
    },
    credited(): number {
      return this.amount + this.income;
    }
  },
  methods: {
    async hideAll() {
      this.globalStore.hideAll()
    }
  }
});
</script>

<style lang="less">


</style>
