import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_popup_error = _resolveComponent("popup-error")!
  const _component_popup_verification = _resolveComponent("popup-verification")!
  const _component_popup_done_verification = _resolveComponent("popup-done-verification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.globalStore.showPopup.errorRequest)
      ? (_openBlock(), _createBlock(_component_popup_error, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.globalStore.showPopup.profileVerification)
      ? (_openBlock(), _createBlock(_component_popup_verification, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.globalStore.showPopup.profileDoneVerification)
      ? (_openBlock(), _createBlock(_component_popup_done_verification, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}