<template>
    <div class="home-content">
        <form-support v-if="globalStore.showPopup.formSupport" />
        <popup-success-support v-else-if="globalStore.showPopup.successSupport" />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import FormSupport from '@/components/form/FormSupport';
import { useGlobalStore } from '@/stores/global'
import PopupSuccessSupport from '@/components/popup/PopupSuccessSupport'
import { COMPONENT_NAME } from '@/stores/types';
export default defineComponent({
  components: {
    PopupSuccessSupport,
    FormSupport
  },
  setup () {
    const globalStore = useGlobalStore();
    return {
      globalStore
    };
  },
  async mounted() {
    this.globalStore.setShowPopup(COMPONENT_NAME.FORM_SUPPORT)
  }
});
</script>
