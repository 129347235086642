<template>
    <div
        id="error_session_pay"
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <img
                class="form-icon-error"
                src="@/assets/icon_popup_error.svg"
                alt=""
            >
            <div class="form-text-status-title">
                Время ожидания закончилось
            </div>
            <div class="form-text-status-description">
                Сессия подтверждения завершена<br>
                Пожалуйста, начините перевод заново
            </div>
            <button class="form-button-submit">
                <router-link
                    to="/steam/#form_pay"
                >
                    На главную
                </router-link>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  setup () {

  }
});
</script>

<style lang="less">
.form-icon-error {
  width: 120px;
  margin: 0 auto;
}
</style>
