<template>
    <div
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <img
                class="form-icon-success"
                src="@/assets/icon_popup_success.svg"
                alt=""
            >
            <div class="form-text-status-title">
                Вы успешно верифицированы!
            </div>
            <div class="form-text-status-description">
                Теперь вы можете выполнять заявки
            </div>
            <button class="form-button-submit">
                <router-link
                    to="/supplier"
                >
                    На главную
                </router-link>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useClientStore } from '@/stores/client';
import { useGlobalStore } from '@/stores/global';

export default defineComponent({
  setup() {
    const store = useClientStore();
    const globalStore = useGlobalStore();
    return {
      store,
      setShowPopup: globalStore.setShowPopup
    };
  }
});
</script>
