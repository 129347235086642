<template>
    <div
        id="form_pay_finish"
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <h2>
                Пополнить Steam
            </h2>
            <template v-if="!paymentUrl">
                <div class="form-error-message">
                    {{ store.checkData.errorMessage }}
                </div>
                <div class="form-pay-info-row">
                    <span>Логин Steam</span>
                    <span>{{ store.checkData.steamAccount }}</span>
                </div>
                <div class="form-pay-info-row">
                    <span>Сумма пополнения</span>
                    <span>{{ store.checkData.usdAmount }} $</span>
                </div>
                <div class="form-pay-info-row">
                    <span>Сумма к оплате</span>
                    <span class="price">{{ amountFormatted }}</span>
                </div>
                <button
                    class="form-button-submit"
                    @click="topUpCheckAndCreate"
                >
                    {{ `Оплатить ${amountFormatted}` }}
                    <div
                        v-if="store.checkData.success"
                        class="form-field-help"
                    >
                        <div class="form-field-help-title">
                            Подтвердите перевод
                        </div>
                        Максимальное время обработки заявки - {{ store.session.timeToTopup }} минут. Если деньги не придут за указанное время, обратитесь в техподдержку.
                    </div>
                </button>
            </template>
            <iframe
                v-else
                class="payment-iframe"
                :src="paymentUrl"
            />
        </div>
    </div>
</template>

<script lang="ts">

import { useGlobalStore } from '@/stores/global';
import { defineComponent } from 'vue';
import { useClientStore } from '@/stores/client';
import { currencySymbol } from '@/utils';
import { decodeHtmlEntity } from '@/utils/html_entity';

export default defineComponent({
  setup() {
    const store = useClientStore();
    const globalStore = useGlobalStore();

    return {
      store,
      globalStore
    };
  },
  data() {
    return {
      paymentUrl: ''
    }
  },
  computed: {
    amountFormatted(): string {
      let symbol = decodeHtmlEntity(currencySymbol[ this.store.checkData.currency ]);
      //TODO после перехода на исопльзования i18n
      // let formatted = Intl.NumberFormat(this.$i18n.locale, {
      //   style: 'currency',
      //   currency: this.formData.currency,
      //   minimumFractionDigits: 0,
      //   maximumFractionDigits: 2
      // }).format(this.formData.amount);
      //
      //if (symbol) {
      //  formatted = formatted.replace(this.formData.currency, symbol);
      //}
      return `${this.store.checkData.amount} ${symbol}`;
    }
  },
  mounted () {
    window.addEventListener('message', this.receiveIframeMessage)
  },
  beforeUnmount () {
    window.removeEventListener('message', this.receiveIframeMessage)
  },
  methods: {
    async topUpCheckAndCreate() {
      await this.store.topUpCreate();

      if (this.store.currentTopUp.status === 'FAILED') {
        // this.globalStore.setShowPopup('errorSessionPay')
        this.$router.push('/steam/#error_session_pay')
      } else {
        this.paymentUrl = this.store.currentTopUp.paymentUrl;
      }
      this.store.clearTopUpCheck()
    },
    receiveIframeMessage (event: any) {
      if(event.data && event.data.type === 'topUpStatusPage') {
        this.$router.push(`/topup/status/${event.data.id}`)
      }
    }
  }

});
</script>

<style lang="less">
  .payment-iframe {
    width: 500px;
    height: 700px;
    border: none;
  }
</style>
