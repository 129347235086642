export const currencySymbol: { [code: string]: string } = {
  'ALL': '&#76;&#101;&#107;',
  'AFN': '&#1547;',
  'ARS': '&#36;',
  'AWG': '&#402;',
  'AUD': '&#36;',
  'BSD': '&#36;',
  'BBD': '&#36;',
  'BYR': '&#112;&#46;',
  'BZD': '&#66;&#90;&#36;',
  'BMD': '&#36;',
  'BOB': '&#36;&#98;',
  'BAM': '&#75;&#77;',
  'BWP': '&#80;',
  'BGN': '&#1083;&#1074;',
  'BRL': '&#82;&#36;',
  'BND': '&#36;',
  'KHR': '&#6107;',
  'CAD': '&#36;',
  'KYD': '&#36;',
  'CLP': '&#36;',
  'CNY': '&#165;',
  'COP': '&#36;',
  'CRC': '&#8353;',
  'HRK': '&#107;&#110;',
  'CUP': '&#8369;',
  'CZK': '&#75;&#269;',
  'DKK': '&#107;&#114;',
  'DOP': '&#82;&#68;&#36;',
  'XCD': '&#36;',
  'EGP': '&#163;',
  'SVC': '&#36;',
  'EEK': '&#107;&#114;',
  'EUR': '&#8364;',
  'FKP': '&#163;',
  'FJD': '&#36;',
  'GHC': '&#162;',
  'GIP': '&#163;',
  'GTQ': '&#81;',
  'GGP': '&#163;',
  'GYD': '&#36;',
  'HNL': '&#76;',
  'HKD': '&#36;',
  'HUF': '&#70;&#116;',
  'ISK': '&#107;&#114;',
  'IDR': '&#82;&#112;',
  'IRR': '&#65020;',
  'IMP': '&#163;',
  'ILS': '&#8362;',
  'JMD': '&#74;&#36;',
  'JPY': '&#165;',
  'JEP': '&#163;',
  'KZT': '&#8376;',
  'KPW': '&#8361;',
  'KGS': '&#1083;&#1074;',
  'LAK': '&#8365;',
  'LVL': '&#76;&#115;',
  'LBP': '&#163;',
  'LRD': '&#36;',
  'LTL': '&#76;&#116;',
  'MKD': '&#1076;&#1077;&#1085;',
  'MYR': '&#82;&#77;',
  'MUR': '&#8360;',
  'MXN': '&#36;',
  'MNT': '&#8366;',
  'MZN': '&#77;&#84;',
  'NAD': '&#36;',
  'NPR': '&#8360;',
  'ANG': '&#402;',
  'NZD': '&#36;',
  'NIO': '&#67;&#36;',
  'NGN': '&#8358;',
  'NOK': '&#107;&#114;',
  'OMR': '&#65020;',
  'PKR': '&#8360;',
  'PAB': '&#66;&#47;&#46;',
  'PYG': '&#71;&#115;',
  'PEN': '&#83;&#47;&#46;',
  'PHP': '&#8369;',
  'PLN': '&#122;&#322;',
  'QAR': '&#65020;',
  'RON': '&#108;&#101;&#105;',
  'RUB': '&#8381;',
  'SHP': '&#163;',
  'SAR': '&#65020;',
  'RSD': '&#1044;&#1080;&#1085;&#46;',
  'SCR': '&#8360;',
  'SGD': '&#36;',
  'SBD': '&#36;',
  'SOS': '&#83;',
  'ZAR': '&#82;',
  'KRW': '&#8361;',
  'LKR': '&#8360;',
  'SEK': '&#107;&#114;',
  'CHF': '&#67;&#72;&#70;',
  'SRD': '&#36;',
  'SYP': '&#163;',
  'TWD': '&#78;&#84;&#36;',
  'THB': '&#3647;',
  'TTD': '&#84;&#84;&#36;',
  'TRL': '&#8356;',
  'TVD': '&#36;',
  'UAH': '&#8372;',
  'GBP': '&#163;',
  'USD': '&#36;',
  'UYU': '&#36;&#85;',
 // removed because this symbol is лв 'UZS': '&#1083;&#1074;',
  'VEF': '&#66;&#115;',
  'VND': '&#8363;',
  'YER': '&#65020;',
  'ZWD': '&#90;&#36;'
};

export function isEnum<EV, ET extends { [key: string]: EV }>(enumValue: EV, enumType: ET): enumValue is ET[keyof ET] {
  return Object.values(enumType).includes(enumValue);
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
