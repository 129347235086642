<template>
    <div class="home-content">
        <last-top-up />
        <popup-error v-if="globalStore.showPopup.errorRequest" />
        <popup-success-support v-else-if="globalStore.showPopup.successSupport" />
        <popup-check-pay v-else-if="globalStore.showPopup.checkPay" />
        <popup-pay-session-error v-else-if="globalStore.showPopup.errorSessionPay" />
        <form-pay v-else-if="globalStore.showPopup.formPay" />
        <form-pay-finish v-else-if="globalStore.showPopup.formPayFinish" />
        <form-support v-else-if="globalStore.showPopup.formSupport" />
    </div>
    <f-a-g />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global';

import PopupError from '@/components/popup/PopupError.vue';
import PopupPaySessionError from '@/components/popup/PopupPaySessionError.vue';
import PopupSuccessSupport from '@/components/popup/PopupSuccessSupport.vue';
import PopupCheckPay from '@/components/popup/PopupCheckPay.vue';
import FormPay from '@/components/form/FormSteamPay.vue';
import FormPayFinish from '@/components/form/FormSteamPayFinish.vue';
import FormSupport from '@/components/form/FormSupport.vue';
import LastTopUp from '@/components/LastTopUp.vue';
import FAG from '@/components/FAG.vue';

export default defineComponent({
  components: {
    PopupError,
    PopupPaySessionError,
    PopupSuccessSupport,
    PopupCheckPay,
    FormPayFinish,
    FormPay,
    FormSupport,
    LastTopUp,
    FAG
  },
  setup () {
    const globalStore = useGlobalStore();
    return {
      globalStore
    };
  },
  async created() {
    // const store = useClientStore();
    // await store.getStartSession()
  }
});
</script>
