import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_last_top_up = _resolveComponent("last-top-up")!
  const _component_popup_error = _resolveComponent("popup-error")!
  const _component_popup_success_support = _resolveComponent("popup-success-support")!
  const _component_popup_check_pay = _resolveComponent("popup-check-pay")!
  const _component_popup_pay_session_error = _resolveComponent("popup-pay-session-error")!
  const _component_form_pay = _resolveComponent("form-pay")!
  const _component_form_pay_finish = _resolveComponent("form-pay-finish")!
  const _component_form_support = _resolveComponent("form-support")!
  const _component_f_a_g = _resolveComponent("f-a-g")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_last_top_up),
      (_ctx.globalStore.showPopup.errorRequest)
        ? (_openBlock(), _createBlock(_component_popup_error, { key: 0 }))
        : (_ctx.globalStore.showPopup.successSupport)
          ? (_openBlock(), _createBlock(_component_popup_success_support, { key: 1 }))
          : (_ctx.globalStore.showPopup.checkPay)
            ? (_openBlock(), _createBlock(_component_popup_check_pay, { key: 2 }))
            : (_ctx.globalStore.showPopup.errorSessionPay)
              ? (_openBlock(), _createBlock(_component_popup_pay_session_error, { key: 3 }))
              : (_ctx.globalStore.showPopup.formPay)
                ? (_openBlock(), _createBlock(_component_form_pay, { key: 4 }))
                : (_ctx.globalStore.showPopup.formPayFinish)
                  ? (_openBlock(), _createBlock(_component_form_pay_finish, { key: 5 }))
                  : (_ctx.globalStore.showPopup.formSupport)
                    ? (_openBlock(), _createBlock(_component_form_support, { key: 6 }))
                    : _createCommentVNode("", true)
    ]),
    _createVNode(_component_f_a_g)
  ], 64))
}