<template>
    <div

        class="template-table"
    >
        <div

            class="template-table-wrap"
        >
            <div

                class="template-table-title"
            >
                История движения средств
            </div>
            <div
                v-if="!requests.length"
                class="template-empty-table-wrap"
            >
                <img
                    src="@/assets/icon_empty_table.svg"
                    class="template-empty-table-icon"
                    alt="empty-table-icon"
                >
                <div class="template-empty-table-text">
                    История пока ещё пуста
                </div>
            </div>
            <table v-if="requests.length">
                <thead>
                    <tr>
                        <th>Тип</th>
                        <th>Дата</th>
                        <th>Статус</th>
                        <th>Сумма</th>
                        <th>&#x20;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(wallet, index) in requests"
                        :key="index"
                    >
                        <td class="mini">
                            {{ convertType(wallet.type) }}
                        </td>
                        <td class="mini">
                            {{ convertTime(wallet.date) }}
                        </td>
                        <td class="mini">
                            <div class="status">
                                {{ convertStatus(wallet.status) }}
                            </div>
                        </td>
                        <td>
                            {{ wallet.amount.toFixed(2) }} &#8376;
                        </td>

                        <td>
                            <button @click="handlePayRequest(wallet)">
                                Чек операции
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global';

import { Wallet, WalletStatus, WalletType } from '@/types/Wallet';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useSupplierStore } from '@/stores/supplier';
dayjs.extend(duration);

export default defineComponent({
  props: {
    requests: {
      type: Array as PropType<Wallet[]>,
      required: true
    }
  },
  setup () {
    const globalStore = useGlobalStore();
    const supplierStore = useSupplierStore();

    return {
      globalStore,
      supplierStore
    };
  },
  data() {
    return {

    }
  },
  computed: {
    convertTime() {
      return (time: string) => {
        return dayjs(time).format('HH:mm DD.MM.YYYY');
      };
    },
    convertType () {
      return (type: keyof typeof WalletType) => {
        return WalletType[ type ];
      };
    },
    convertStatus () {
      return (type: keyof typeof WalletStatus) => {
        return WalletStatus[ type ];
      };
    }
  },
  async mounted() {

  },
  methods: {
    handlePayRequest(wallet: Wallet) {
      this.globalStore.setShowPopup('walletCheck')
      this.supplierStore.setActiveWallet(wallet)
    }
  }
});
</script>

<style lang="less">
@import "~@/styles/table.less";
</style>
