import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_last_top_up = _resolveComponent("last-top-up")!
  const _component_popup_success = _resolveComponent("popup-success")!
  const _component_popup_pay_error = _resolveComponent("popup-pay-error")!
  const _component_popup_loading = _resolveComponent("popup-loading")!
  const _component_popup_error = _resolveComponent("popup-error")!
  const _component_f_a_g = _resolveComponent("f-a-g")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_last_top_up),
      (_ctx.globalStore.showPopup.successPay)
        ? (_openBlock(), _createBlock(_component_popup_success, { key: 0 }))
        : (_ctx.globalStore.showPopup.errorPay)
          ? (_openBlock(), _createBlock(_component_popup_pay_error, { key: 1 }))
          : (_ctx.globalStore.showPopup.loadingPay)
            ? (_openBlock(), _createBlock(_component_popup_loading, { key: 2 }))
            : (_ctx.globalStore.showPopup.errorRequest)
              ? (_openBlock(), _createBlock(_component_popup_error, { key: 3 }))
              : _createCommentVNode("", true)
    ]),
    _createVNode(_component_f_a_g)
  ], 64))
}