<template>
    <div
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <span
                class="form-icon-loader icon-loader"
            />
            <div class="form-text-status-title">
                Платёж обрабатывается...
            </div>
            <div class="form-text-status-description">
                Это займет несколько секунд.<br>
                Логин Steam {{ store.currentTopUp.account }}<br>
                Сумма - {{ store.currentTopUp.amountUsd || 0 }} USD<br>
                {{ formatTime(store.currentTopUp.creationDate) }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useClientStore } from '@/stores/client';
import { useGlobalStore } from '@/stores/global';
import dayjs from 'dayjs';

export default defineComponent({
  setup () {
    const store = useClientStore();
    const globalStore = useGlobalStore();
    return {
      store,
      setShowPopup: globalStore.setShowPopup
    };
  },
  methods: {
    formatTime(time: number) {
      const date = dayjs(time * 1000);

      return date.format('HH:mm DD.MM.YYYY');
    }
  }
});
</script>

<style lang="less">
.form-icon-loader {
  margin: 20px auto;
}
</style>
