<template>
    <div class="template-bg">
        <div class="template-wrap">
            <div class="template-header-wrap">
                <div class="template-header-logo">
                    <router-link
                        to="/supplier"
                    >
                        <img
                            src="@/assets/logo_template.svg"
                            alt="topupme"
                        >
                    </router-link>
                </div>
                <supplier-navigation />
                <supplier-user />
            </div>
            <div class="template-content-wrap">
                <slot />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global'
import SupplierNavigation from '@/components/supplier/SupplierNavigation.vue';
import SupplierUser from '@/components/supplier/SupplierUserBox.vue';

export default defineComponent({
  components: {
    SupplierNavigation,
    SupplierUser
  }
});
</script>
