<template>
    <component :is="currentTemplate">
        <router-view />
    </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TemplateSteam from '@/views/template/TemplateSteam.vue';
import TemplateSupplier from '@/views/template/TemplateSupplier.vue';
import TemplateMain from '@/views/template/TemplateMain.vue';
import TemplateEmpty from '@/views/template/TemplateEmpty.vue';

export default defineComponent({
  components: {
    TemplateSteam,
    TemplateSupplier,
    TemplateMain,
    TemplateEmpty
  },
  computed: {
    currentTemplate() {
      return this.$route.meta.template
    }
  }
});
</script>

<style lang="less">
@import "~@/styles/app.less";
@import "~@/styles/navigation.less";
@import "~@/styles/template.less";
@import "~@/styles/collapse.less";
</style>
