import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "form-fly-wrap" }
const _hoisted_2 = { class: "form-content" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, [
        _createTextVNode(" Редактирование реквизитов "),
        _createElementVNode("span", {
          class: "form-close",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
        })
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.payoutDestinations, ({ name, destinationId }) => {
        return (_openBlock(), _createElementBlock("div", {
          key: destinationId,
          class: "form-cart-item"
        }, [
          _createElementVNode("div", null, _toDisplayString(name), 1),
          _createElementVNode("button", {
            class: _normalizeClass(["form-cart-delete", {progress: _ctx.cartDeleteDestinationId === destinationId}]),
            type: "button",
            onClick: ($event: any) => (_ctx.cartDelete(destinationId))
          }, null, 10, _hoisted_3)
        ]))
      }), 128)),
      _createElementVNode("div", {
        class: "form-open-cart-edit",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openCardAdd && _ctx.openCardAdd(...args)))
      }, " + Добавить новую карту ")
    ])
  ]))
}