<template>
    <popup-request-pay v-if="globalStore.showPopup.requestPay" />
    <popup-request-success v-if="globalStore.showPopup.requestPaySuccess" />
    <popup-request-check v-if="globalStore.showPopup.requestCheck" />
    <!--    <popup-verification v-if="globalStore.showPopup.profileVerification" />-->
    <popup-supplier-error v-if="globalStore.showPopup.errorRequest" />

    <div class="home-content">
        <el-tabs
            v-model="activeName"
            class="demo-tabs"
        >
            <el-tab-pane
                label="Список заявок"
                name="available"
            >
                <supplier-request-available-tables
                    :requests="supplierStore.requests.available"
                    :active="activeName === 'available'"
                />
            </el-tab-pane>
            <el-tab-pane
                label="Выполненные заявки"
                name="processed"
            >
                <supplier-request-processed-tables
                    :requests="supplierStore.requests.processed"
                    :active="activeName === 'processed'"
                />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useGlobalStore } from '@/stores/global';

import SupplierRequestAvailableTables from '@/components/supplier/SupplierRequestAvailableTables.vue';
import SupplierRequestProcessedTables from '@/components/supplier/SupplierRequestProcessedTables.vue';
import PopupSupplierError from '@/components/popup/PopupSupplierError.vue';
import PopupRequestPay from '@/components/popup/PopupRequestPay.vue';
import PopupRequestSuccess from '@/components/popup/PopupRequestSuccess.vue';
import PopupRequestCheck from '@/components/popup/PopupRequestCheck.vue';
// import PopupVerification from '@/components/popup/PopupVerification.vue';
import { useSupplierStore } from '@/stores/supplier';

export default defineComponent({
  components: {
    SupplierRequestAvailableTables,
    SupplierRequestProcessedTables,
    PopupRequestPay,
    PopupRequestSuccess,
    PopupRequestCheck,
    // PopupVerification,
    PopupSupplierError
  },
  setup () {
    const globalStore = useGlobalStore();
    const supplierStore = useSupplierStore();

    return {
      supplierStore,
      globalStore,
      activeName: ref('available')
    };
  }
});
</script>

<style>

.el-tabs {
  min-height: 100vh;
  padding-top: 100px;

}

.el-tabs__header {
  margin: 60px 0;
}

.el-tabs__nav-wrap:after, .el-tabs__active-bar {
  visibility: hidden;
}

.el-tabs__item {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  padding: 0 25px;
  color: #D6D5D6;

  &.is-active, &:hover {
     color: #E88102;
  }
}

</style>
