export enum WalletType {
    INCOME = 'Вывод',
    OUTCOME = 'Получение'
}

export enum WalletStatus {
    PENDING = 'Обрабатывается',
    DONE = 'Успешно',
    FAILED = 'Отменено'
}

export interface Wallet {
    date: string,
    type: keyof typeof WalletType,
    amount: number,
    status: keyof typeof WalletStatus,
}


